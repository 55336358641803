body {
	font-family: Montserrat, sans-serif;
	color: #333;
	font-weight: 400;
}

.nav-link {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	margin-right: 0rem;
	margin-bottom: 0rem;
	margin-left: 0px;
	padding: 1.65rem 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 3.5px none #fff;
	-webkit-transition: background-color 350ms ease;
	transition: background-color 350ms ease;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 2px;
	text-decoration: none;
	text-transform: uppercase;
}

.nav-link:hover {
	width: auto;
	border-bottom: 3.5px none #fdb827;
	background-color: transparent;
	color: #333;
}

.nav-link.w--current {
	background-color: hsla(0, 0%, 100%, 0.4);
	color: #000;
	font-weight: 600;
}

.nav-link.inside-page-nav-link {
	width: auto;
	height: 100%;
	padding-right: 1.65rem;
	padding-left: 1.65rem;
	border: 1px none #000;
}

.nav-link.inside-page-nav-link.first-nav-link {
	border-left-style: solid;
}

.navbar {
	position: relative;
	left: 0%;
	top: 0%;
	right: 0%;
	z-index: 5000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 300px;
	height: 100vh;
	max-width: 240px;
	padding: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 4px none #003a70;
	background-color: #ddd;
}

.navbar.inside-navbar {
	position: -webkit-sticky;
	position: sticky;
	width: 100%;
	height: auto;
	max-width: none;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.navbar.option-2-inside-navbar {
	position: -webkit-sticky;
	position: sticky;
}

.nav-menu {
	z-index: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 60vh;
	margin-right: 0rem;
	margin-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.nav-menu.inside-menu {
	width: auto;
	height: auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.home-content-section {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: visible;
	width: 100%;
	height: 100vh;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.body {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: visible;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.footer-content-section {
	position: relative;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	width: 100%;
	height: auto;
	margin-left: 0px;
	background-color: transparent;
}

.footer-content-section.homepage-positioning {
	position: absolute;
}

.footer-col-rights {
	position: relative;
	z-index: 100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-right: 0px;
	margin-left: 0px;
	padding-bottom: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.grip-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-right: 0px;
	margin-left: auto;
	padding-bottom: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box.webdevby-white {
	color: #fff;
}

.footer-container {
	position: static;
	left: 10%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	max-width: none;
	padding: 1rem 4rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-top: 1px none rgba(255, 255, 255, 0.25);
	background-color: transparent;
}

.footer-container.inside-page-footer {
	background-color: #000;
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/globalreach_dark.svg');
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link.white {
	width: 140px;
	margin-top: 0rem;
	margin-bottom: 0px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/globalreach_white.svg');
	background-size: 150px 29px;
	opacity: 1;
}

.footer-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 0px 20px 0px 0px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.75rem;
	font-weight: 300;
	text-align: left;
	letter-spacing: 0px;
	text-transform: capitalize;
	cursor: pointer;
}

.footer-text:hover {
	border-bottom: 3px none #3e85ed;
	color: #fdb827;
	text-decoration: none;
}

.footer-text.rights {
	margin-right: 1rem;
	margin-bottom: 0px;
	opacity: 1;
	font-family: Montserrat, sans-serif;
	font-size: 0.735rem;
	line-height: 0.85rem;
	font-weight: 300;
}

.footer-text.rights h1 {
	display: inline;
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	margin: 0px;
	padding: 0px;
	font-size: inherit;
}

.footer-text.rights:hover {
	border-bottom-style: none;
	color: #fff;
}

.alert-banner {
	position: relative;
	z-index: 1000;
	height: 45px;
	background-color: #fdb827;
}

.news-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-decoration: none;
}

.events-inner-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0.5rem;
	padding-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-top: 1px solid #e8e8e8;
	border-bottom: 1px none rgba(51, 51, 51, 0.1);
	background-color: transparent;
	text-decoration: none;
}

.full-hero-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100vh;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))), -webkit-gradient(linear, left bottom, left top, from(#000), color-stop(70%, transparent)), url('/20170713122722/local/public/shared/assets/images/websites/bradleyriley-exterior-3.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(360deg, #000, transparent 70%), url('/20170713122722/local/public/shared/assets/images/websites/bradleyriley-exterior-3.jpg');
	background-position: 0px 0px, 0px 0px, 50% 50%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
	background-attachment: scroll, scroll, scroll;
}

.mobile-menu-search-wrap {
	display: block;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.news-inner-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0.5rem;
	padding-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-top: 1px solid #e8e8e8;
	border-bottom: 1px none rgba(51, 51, 51, 0.1);
	background-color: transparent;
	text-decoration: none;
}

.underline {
	position: relative;
	top: -10px;
	width: 0%;
	height: 10px;
	background-color: #fdb827;
}

.underline-2 {
	position: relative;
	top: -20px;
	width: 0%;
	height: 20px;
	background-color: #fdb827;
}

.underline-3 {
	position: relative;
	top: -20px;
	width: 0%;
	height: 20px;
	background-color: #fdb827;
}

.underline-4 {
	width: 0%;
	height: 3px;
	background-color: #fdb827;
}

.district-underline {
	width: 0%;
	height: 3px;
	background-color: #fdb827;
}

.district-underline-2 {
	width: 0%;
	height: 3px;
	background-color: #fdb827;
}

.search-bar {
	position: static;
	left: auto;
	top: 48px;
	right: 0px;
	bottom: auto;
	z-index: 100;
	display: none;
	overflow: hidden;
	width: auto;
	max-width: 80vw;
	margin-left: 0px;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px none rgba(51, 51, 51, 0.1);
	background-color: #fff;
	font-family: Montserrat, sans-serif;
}

.mobile-link {
	display: none;
	margin-right: auto;
	margin-left: auto;
	padding-top: 10px;
	padding-bottom: 5px;
	float: right;
	color: #002f55;
	font-size: 16px;
	text-align: center;
}

.mobile-link:hover {
	border-bottom: 5px none #000;
}

.breadcrumb-list-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-right: 0.5rem;
	margin-bottom: 0rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.75rem;
}

.section-wrap {
	overflow: hidden;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: none;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem 0px;
	border-top: 5px none #9daad4;
	background-color: #fff;
}

.horiz-divider {
	height: 1px;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	background-color: rgba(91, 91, 91, 0.15);
	color: #003c43;
}

.horiz-divider.option-2 {
	margin-top: 0rem;
}

.inside-page-background-image {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 35vh;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding: 1rem 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: transparent;
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.8), hsla(0, 0%, 100%, 0) 67%);
	background-position: 0px 0px, 50% 80%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.inside-page-background-image.option-2 {
	padding-right: 4rem;
	padding-left: 4rem;
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.8), hsla(0, 0%, 100%, 0) 84%);
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.breadcrumb-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	max-width: none;
	margin: 1.5rem 0.125rem 1.5rem 0rem;
	padding-left: 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.inside-background-heading-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	max-width: 1500px;
	margin-right: auto;
	margin-left: 325px;
	padding-right: 5rem;
	padding-left: 5rem;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.inside-background-heading-wrapper.option-2 {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.inside-background-heading-wrapper.full-width {
	max-width: 1400px;
	margin-right: 0px;
	margin-left: 0px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.breadcrumb-list-wrapper {
	width: auto;
	max-width: 1400px;
}

.main-content-col {
	width: 100%;
	padding-right: 5rem;
	padding-left: 5rem;
	min-height: 500px;
}

.main-content-col.fullwidth {
	max-width: 1400px;
}

.left-nav-col {
	display: block;
	padding-right: 0px;
	padding-left: 0px;
}

.main-content-container {
	display: block;
	width: 100%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 0rem;
	padding-left: 0rem;
}

.main-content-container.option-2 {
	padding: 1rem 4rem 4rem;
}

.content-box-links {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-decoration: none;
}

.brand-logo {
	display: block;
	width: 175px;
	margin: 0rem;
	float: none;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-bottom-style: none;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
}

.brand-logo:hover {
	color: #fff;
}

.brand-logo.preloader {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 225px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.brand-logo.inside-page-brand-logo {
	width: 225px;
}

.logo-image.mobile-view {
	display: none;
}

.logo-image.mobile-view.inside-page-view {
	display: block;
}

.logo-image.hide-on-mobile.hide-on-inside-page {
	display: none;
}

.footer-signoff-link {
	margin-right: 1rem;
	margin-left: 0.5rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-bottom: 1px none rgba(255, 255, 255, 0.2);
	opacity: 1;
	-webkit-transition: border-color 350ms ease, color 200ms ease;
	transition: border-color 350ms ease, color 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.735rem;
	line-height: 0.85rem;
	font-weight: 300;
	letter-spacing: 0.4px;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.footer-signoff-link:hover {
	border-bottom: 1px none #fff;
	opacity: 1;
	color: #fff;
}

.nav-menu-divider {
	display: none;
	width: 50px;
	height: 2px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: #333;
}

.all-headings {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	margin-bottom: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: Montserrat, sans-serif;
	font-size: 3rem;
	line-height: 3rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 1px;
	text-transform: capitalize;
}

.all-headings.hero {
	position: relative;
	z-index: 10;
	margin-bottom: 1rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 3.5rem;
	line-height: 3.25rem;
	font-weight: 300;
	text-align: left;
	letter-spacing: -0.03em;
	text-transform: none;
}

.intro-text {
	position: relative;
	z-index: 10;
	display: block;
	width: 100%;
	max-width: none;
	margin-bottom: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 1.05rem;
	line-height: 1.25rem;
	font-style: normal;
	font-weight: 200;
	text-align: left;
	letter-spacing: 0px;
	text-transform: none;
}

.intro-text.bold {
	font-weight: 500;
}

.nav-link-wrapper {
	position: relative;
	z-index: 0;
	display: block;
	width: 100%;
	padding: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid #e8e8e8;
	-webkit-transition: background-color 350ms ease;
	transition: background-color 350ms ease;
}

.nav-link-wrapper:hover {
	background-color: rgba(255, 255, 255, 0.4);
}

.nav-link-wrapper.first-nav-link {
	border-top: 1px none #e8e8e8;
}

.nav-link-wrapper.first-nav-link.inside-page-nav-link {
	border-right: 1px solid #e8e8e8;
	border-left: 1px solid #e8e8e8;
	border-bottom-style: none;
}

.nav-link-wrapper.last-nav-link {
	border-bottom-style: none;
}

.nav-link-wrapper.last-nav-link.contact-us {
	display: none;
}

.nav-link-wrapper.last-nav-link.inside-page-nav-iink {
	width: auto;
	margin-right: 2rem;
	border-right: 1px solid #e8e8e8;
}

.nav-link-wrapper.inside-page-nav-link {
	width: auto;
	border-right: 1px solid #e8e8e8;
	border-left: 1px none #e8e8e8;
	border-bottom-style: none;
}

.nav-highlight {
	position: absolute;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0.7rem;
	z-index: -1;
	display: none;
	width: 100%;
	height: 2px;
	background-color: #000;
}

.image-overlay {
	position: relative;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: auto;
	height: 60vh;
	padding: 2rem 4rem 4rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.35)), to(rgba(0, 0, 0, 0.35)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
}

.stripe-container {
	position: absolute;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.pin-stripe {
	width: 1px;
	background-color: rgba(49, 61, 63, 0.08);
}

.pin-stripe.white {
	display: block;
	background-color: rgba(255, 255, 255, 0.35);
}

.pin-stripe.white.hide-tablet {
	background-color: hsla(0, 0%, 100%, 0.35);
}

.pin-stripe.white.hide-horizontal-mobile {
	background-color: hsla(0, 0%, 100%, 0.35);
}

.hero-img-wrapper {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 1000;
	display: none;
	overflow: hidden;
	height: auto;
	max-width: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.stripe-wrapper {
	position: absolute;
	left: auto;
	top: 0%;
	right: auto;
	bottom: 0%;
	z-index: 5;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	min-width: 100vw;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0vw;
	padding-left: 0vw;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.wrapper {
	position: relative;
	z-index: 10;
	width: 100%;
	max-width: 1400px;
}

.wrapper.no-padding {
	z-index: 10;
	max-width: none;
	padding-right: 0px;
	padding-left: 0px;
}

.page-load-animation {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 10000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #000;
}

.preloader-content {
	display: -ms-grid;
	display: grid;
	width: 100%;
	max-width: 275px;
	justify-items: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 36px;
	-ms-grid-columns: 1fr;
	grid-template-columns: 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.preloader-indicator {
	width: 100%;
}

.indicator-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 3px;
	background-color: rgba(136, 158, 168, 0.2);
}

.indicator-fill {
	width: 0%;
	height: 100%;
	background-color: #ddd;
}

.logo-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 40vh;
	max-height: none;
	padding: 3rem 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #000;
}

.logo-container.inside-page-logo-container {
	width: 325px;
	height: auto;
	margin-right: auto;
	padding-top: 1rem;
	padding-bottom: 1rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.primary-button {
	margin-top: 2rem;
	padding: 0.75rem 1.25rem;
	border-style: solid;
	border-width: 1px;
	border-color: #fff;
	background-color: transparent;
	opacity: 1;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.65rem;
	font-weight: 500;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.primary-button:hover {
	background-color: #fff;
	opacity: 1;
	color: #000;
}

.primary-button.button-style {
	margin-top: 0rem;
	margin-right: 1rem;
	border-color: #fff;
	color: #fff;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.primary-button.button-style:hover {
	color: #000;
}

.breadcrumb-link {
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 #000 transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #4c4c4c;
	font-size: 0.85rem;
	line-height: 1rem;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.breadcrumb-link:hover {
	border-bottom-style: solid;
	border-bottom-color: rgba(82, 82, 82, 0.12);
	color: #6e6e6e;
	font-weight: 500;
	text-transform: capitalize;
}

.breadcrumb-link.w--current {
	border-bottom-style: none;
	color: #000;
	font-weight: 600;
}

.breadcrumb-link.white {
	color: #fff;
	font-weight: 400;
}

.breadcrumb-link.white.w--current {
	font-weight: 500;
}

.inside-page-header-wrap-2 {
	display: none;
	margin-bottom: 0rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 2.25rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 0.5px none rgba(51, 51, 51, 0.15);
	background-color: #fff;
}

.breadcrumb-divider {
	display: inline;
	margin-right: 1rem;
	margin-left: 1rem;
	font-family: Montserrat, sans-serif;
	color: #fdb827;
	font-weight: 900;
}

.breadcrumb-divider.breadcrumb-arrow {
	margin-right: 0.25rem;
	margin-left: 0.5rem;
	color: #4c4c4c;
	font-weight: 500;
}

.h1-heading {
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 45px;
	font-weight: 300;
}

.h1-heading.black {
	margin-bottom: 1rem;
	color: #000;
}

.h4-heading-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.12);
	font-family: Montserrat, sans-serif;
	color: #6e6e6e;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-decoration: none;
}

.h4-heading-link:hover {
	border-bottom-color: rgba(82, 82, 82, 0.12);
	color: #6e6e6e;
}

.unordered-list {
	margin-bottom: 1rem;
}

.h3-heading {
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-weight: 400;
}

.h3-heading.section-title {
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 1rem;
	background-color: #fafafa;
}

.button-styling-wrapper {
	display: inline-block;
	width: auto;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: silver;
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.image.recent-blog-post {
	width: 100%;
	height: 100%;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/man-on-computer.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: repeat;
}

.image._2 {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/AdobeStock_446315472-copy.jpg');
	background-position: 50% 25%;
}

.h6-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #6e6e6e;
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.h6-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #6e6e6e;
}

.h6-heading {
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-weight: 500;
	text-transform: capitalize;
}

.placeholder-image {
	position: relative;
	overflow: hidden;
	width: 75%;
	height: 375px;
}

.placeholder-image.inside-page {
	width: 100%;
	height: 325px;
}

.main-content-section {
	width: 100%;
	max-width: none;
	padding-top: 1rem;
	padding-right: 0rem;
	padding-bottom: 4rem;
	border: 1px none #000;
}

.main-content-section.option-2 {
	padding-top: 0rem;
	border-top-style: none;
}

.main-content-section.with-right-sidebar {
	width: 80%;
}

.block-quote {
	margin-top: 2rem;
	margin-bottom: 2rem;
	padding: 1rem 1.5rem;
	border-left-color: #000;
	background-color: transparent;
	font-family: Montserrat, sans-serif;
	color: #6e6e6e;
	font-size: 1.3rem;
	line-height: 1.75rem;
	font-weight: 500;
}

.ordered-list {
	margin-bottom: 1rem;
	padding-left: 20px;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.table-div {
	font-family: Montserrat, sans-serif;
	font-size: 0.8rem;
	font-weight: 400;
}

.h2-heading-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #6e6e6e;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 400;
	text-decoration: none;
}

.h2-heading-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #6e6e6e;
}

.h4-heading {
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-align: left;
}

.paragraph {
	margin-bottom: 1.5rem;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.paragraph.list-item {
	margin-bottom: 1rem;
}

.paragraph.small {
	margin-bottom: 1rem;
	margin-left: 0rem;
	font-size: 0.85rem;
}

.h2-heading {
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-weight: 400;
}

.h5-heading-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #6e6e6e;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.h5-heading-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #6e6e6e;
}

.paragraph-text-link {
	display: inline;
	margin-left: 0rem;
	border-style: none none solid;
	border-width: 1.5px;
	border-color: rgba(82, 82, 82, 0.2) rgba(82, 82, 82, 0.2) rgba(82, 82, 82, 0.12);
	font-family: Montserrat, sans-serif;
	color: #6e6e6e;
	font-size: 1rem;
	line-height: 1.35rem;
	font-weight: 600;
	text-decoration: none;
}

.paragraph-text-link:hover {
	border-bottom-color: transparent;
}

.paragraph-text-link.right-sidebar {
	margin-left: 1.5rem;
	padding-left: 0rem;
	border-left-style: none;
	font-size: 0.85rem;
}

.intro-paragraph {
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 1.25rem;
	line-height: 1.65rem;
	font-weight: 500;
}

.h3-heading-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #6e6e6e;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
	text-decoration: none;
}

.h3-heading-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #aa1e47;
}

.h5-heading {
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-weight: 500;
	text-transform: capitalize;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 1.5rem;
	border: 2px none transparent;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 0.85em;
	line-height: 1.375em;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.left-nav-nested-list-link:hover {
	border: 2px none #48bdd7;
	background-color: transparent;
	background-image: none;
	color: #0915f8;
}

.left-nav-nested-list-link.w--current {
	color: #5e8dbe;
}

.left-nav-nested-list-link.w--current:hover {
	color: #0915f8;
}

.left-nav-nested-list-link.grandchild {
	padding: 0.75rem 2.25rem 0.75rem 3rem;
	border-style: solid none;
	border-width: 3px 1px;
	border-color: #fff #000;
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	color: #000;
	font-size: 0.85rem;
	line-height: 1.375rem;
	font-weight: 500;
	text-transform: none;
}

.left-nav-nested-list-link.grandchild:hover {
	padding-left: 3.25rem;
	border-color: #fff transparent;
	color: #000;
}

.left-nav-nested-list-link.grandchild.w--current {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 3rem;
	border-style: solid none;
	border-width: 3px 1px;
	border-color: #fff #000;
	background-color: transparent;
	color: #000;
	font-size: 0.85rem;
	font-weight: 600;
}

.left-nav-nested-list-link.grandchild.w--current:hover {
	border-top-style: solid;
	border-top-color: #fff;
	border-bottom-style: solid;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-nested-list-link.great-grandchild {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 4rem;
	border-style: solid none;
	border-width: 3px 1px;
	border-color: #fff #000;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #000;
	font-size: 0.8rem;
	font-weight: 500;
}

.left-nav-nested-list-link.great-grandchild:hover {
	padding-left: 4.25rem;
	border-style: solid none;
	border-top-width: 3px;
	border-top-color: #fff;
	border-bottom-width: 3px;
	border-bottom-color: #fff;
	color: #000;
}

.left-nav-nested-list-link.great-grandchild.w--current {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 4rem;
	border-style: solid none;
	border-width: 3px 1px;
	border-color: #fff #000;
	color: #000;
	font-size: 0.8rem;
	font-weight: 600;
}

.left-nav-nested-list-link.great-grandchild.w--current:hover {
	padding-left: 4.25rem;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	width: 325px;
	height: auto;
	max-width: 325px;
	margin-top: 0rem;
	margin-right: 0rem;
	padding-bottom: 0rem;
	border-right: 1px none rgba(92, 92, 92, 0.15);
	background-color: #fafafa;
}

.left-nav-list-content-wrap.option-2 {
	position: relative;
	top: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-right: 0rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.left-nav-list-link {
	display: block;
	padding: 1.1rem 2.25rem 1.1rem 2rem;
	border-style: solid none;
	border-width: 3px 1px;
	border-color: #fff #000;
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 0.85rem;
	line-height: 1rem;
	font-weight: 500;
	text-decoration: none;
	text-transform: none;
}

.left-nav-list-link:hover {
	padding-left: 2.25rem;
	background-color: transparent;
	color: #000;
	text-decoration: none;
}

.left-nav-list-link.w--current {
	padding-left: 2rem;
	border-top: 3px solid #fff;
	border-bottom: 3px solid #fff;
	border-left: 3px none #aa1e47;
	background-color: transparent;
	color: #000;
	font-weight: 600;
}

.left-nav-list-link.w--current:hover {
	padding-left: 2.25rem;
	border-left-style: none;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #000;
}

.left-nav-list-link.option-2 {
	margin-right: 1.5rem;
	margin-left: 1.5rem;
	padding: 2rem 0rem;
	border-style: none none solid;
	border-width: 1px 1px 3px;
	border-color: #000 #000 transparent;
	background-image: none;
	color: #000;
	font-size: 0.85rem;
}

.left-nav-list-link.option-2:hover {
	border-bottom-color: rgba(82, 82, 82, 0.12);
	color: #6e6e6e;
}

.left-nav-list-link.option-2.first {
	margin-left: 0rem;
	border: 1px none #000;
}

.left-nav-list-link.option-2.first:hover {
	border: 1px none #000;
	color: #6e6e6e;
	font-weight: 500;
}

.left-nav-list-link.option-2.first.w--current {
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-bottom: 3px solid #000;
}

.left-nav-list-link.option-2.first.w--current:hover {
	color: #000;
	font-weight: 600;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-top: 2px none #498fa3;
	background-color: rgba(255, 255, 255, 0.15);
}

.left-nav-nested-list.great-grandchild-link {
	border: 1px none #000;
	background-color: #fff;
}

.left-nav-list-item {
	display: block;
	margin-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	border-bottom: 1px solid rgba(51, 51, 51, 0.05);
}

.left-nav-list-item.grandchild-wrapper {
	border-bottom-style: none;
	background-color: rgba(51, 51, 51, 0.05);
}

.left-nav-list-item.section-title {
	display: none;
}

.left-nav-list-item.option-2 {
	border-right: 1px none rgba(82, 82, 82, 0.12);
	border-bottom-style: none;
}

.left-nav-list-item.option-2.last {
	border-right-style: none;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	padding-left: 0px;
	border: 1px none #000;
}

.left-nav-list.option-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0rem;
	padding-left: 4rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.left-nav-nested-list-item {
	margin-bottom: 0px;
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 #000 rgba(51, 51, 51, 0.05);
}

.left-nav-nested-list-item:hover {
	border-top-style: none;
}

.left-nav-nested-list-item.great-grandchild-wrapper {
	border: 1px none #000;
	background-color: #d9d9d4;
}

.secondary-button {
	margin-top: 0rem;
	margin-right: 1rem;
	padding: 0.75rem 1.25rem;
	border-style: none;
	border-width: 1px;
	border-color: #6e6e6e;
	background-color: #6e6e6e;
	opacity: 1;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.65rem;
	font-weight: 500;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.secondary-button:hover {
	border-style: none;
	border-color: #000;
	background-color: #000;
	opacity: 1;
	color: #fff;
}

.secondary-button.form-submit-button {
	margin-top: 1rem;
}

.tertiary-button {
	margin-top: 0rem;
	margin-right: 0rem;
	padding: 0.75rem 1.25rem;
	border-style: none;
	border-width: 1px;
	border-color: #6e6e6e;
	background-color: #000;
	opacity: 1;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.65rem;
	font-weight: 500;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.tertiary-button:hover {
	border-style: none;
	border-color: #000;
	background-color: #6e6e6e;
	opacity: 1;
	color: #fff;
}

.div-block-141 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.breadcrumbs-wrapper {
	width: 100%;
	margin-bottom: 0rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(91, 91, 91, 0.15);
}

.right-sidebar-wrapper {
	margin-bottom: 0.5rem;
}

.right-nav-link {
	position: relative;
	display: inline;
	margin-right: 0px;
	margin-left: 0rem;
	padding: 0.75rem 0px 0.125rem;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 0.85rem;
	line-height: 1rem;
	text-align: left;
	text-decoration: none;
}

.right-nav-link:hover {
	color: #6e6e6e;
}

.right-nav-link.w--current {
	border-bottom-color: #edb761;
}

.paragraph-2 {
	margin-bottom: 0px;
	padding-top: 0rem;
	padding-bottom: 0rem;
	font-family: 'Open Sans', sans-serif;
	color: #525252;
	font-size: 0.95rem;
	text-align: center;
	text-decoration: none;
}

.paragraph-2.small {
	padding-top: 0rem;
	padding-left: 1.5rem;
	font-size: 0.8rem;
	text-align: left;
}

.h4-heading-2 {
	margin-bottom: 5px;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
	text-align: left;
}

.right-sidebar {
	width: 20%;
	margin-top: 0px;
	margin-left: 1.65rem;
	padding: 0.5rem 0px 0px 1.65rem;
	border-right: 6px none #edb761;
	border-left: 1px solid rgba(92, 92, 92, 0.15);
}

.paragraph-text-link-2 {
	display: inline;
	border-style: none none solid;
	border-width: 1.5px;
	border-color: rgba(82, 82, 82, 0.2);
	color: #aa1e47;
	font-size: 1rem;
	font-weight: 500;
	text-decoration: none;
}

.paragraph-text-link-2:hover {
	border-bottom-color: transparent;
}

.paragraph-text-link-2.right-sidebar-row {
	display: inline-block;
	margin-top: 1rem;
	margin-left: 1.5rem;
	font-size: 0.8rem;
}

.right-sidebar-row {
	margin-bottom: 2rem;
}

.inside-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.inside-row.fullwidth {
	width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.form-label {
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-weight: 500;
}

.text-field {
	margin-bottom: 0.5rem;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(92, 92, 92, 0.15);
	font-family: Montserrat, sans-serif;
	font-style: normal;
	font-weight: 400;
}

.primary-button-2 {
	display: inline-block;
	margin-top: 2rem;
	margin-right: 1rem;
	padding: 0.65rem 1rem;
	border-radius: 5px;
	background-color: #aa1e47;
	-webkit-transition: all 300ms ease, box-shadow 300ms ease, background-color 300ms ease, -webkit-transform 300ms ease;
	transition: all 300ms ease, box-shadow 300ms ease, background-color 300ms ease, -webkit-transform 300ms ease;
	transition: all 300ms ease, box-shadow 300ms ease, transform 300ms ease, background-color 300ms ease;
	transition: all 300ms ease, box-shadow 300ms ease, transform 300ms ease, background-color 300ms ease, -webkit-transform 300ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 0.95rem;
	font-weight: 600;
	letter-spacing: 0px;
	text-decoration: none;
	text-transform: none;
}

.primary-button-2:hover {
	background-color: #bb355c;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.27);
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	border-style: none;
	border-width: 1px;
	border-color: rgba(92, 92, 92, 0.15);
	border-radius: 0px;
	background-color: rgba(154, 199, 151, 0.5);
	color: #fff;
}

.screen-message.error {
	border-style: none;
	border-width: 1px;
	border-color: rgba(92, 92, 92, 0.15);
	border-radius: 0px;
	background-color: rgba(198, 40, 40, 0.15);
	color: #fff;
}

.screen-message-text {
	font-family: Montserrat, sans-serif;
	color: #213008;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 500;
}

.screen-message-text.fail {
	color: #aa1a1a;
}

.screen-message-text-2 {
	color: #1c3d66;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

.screen-message-text-2.error {
	color: #aa1e47;
}

.form {
	margin-bottom: 1rem;
}

.invisible-block {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	height: 130px;
	background-color: transparent;
}

.inner-main-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.inner-main-content-wrapper.full-width {
	max-width: 1300px;
}

.left-nav-nested-list-item-link {
	display: block;
	padding: 0.75rem 2.25rem 0.75rem 3rem;
	border-style: solid none;
	border-width: 3px 2px;
	border-color: #fff transparent;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 0.85rem;
	line-height: 1.375rem;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.left-nav-nested-list-item-link:hover {
	padding-left: 3.25rem;
	border-style: solid none;
	border-width: 3px 2px;
	border-color: #fff #48bdd7;
	background-color: transparent;
	background-image: none;
	color: #333;
}

.left-nav-nested-list-item-link.w--current {
	color: #000;
	font-weight: 600;
}

.left-nav-nested-list-item-link.w--current:hover {
	padding-left: 3.25rem;
	color: #000;
}

.left-nav-nested-list-item-link.grandchild {
	padding: 0.75rem 2.25rem 0.75rem 3rem;
	border-style: solid none;
	border-width: 3px 1px;
	border-color: #fff #000;
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	color: #000;
	font-size: 0.85rem;
	font-weight: 500;
	text-transform: none;
}

.left-nav-nested-list-item-link.grandchild:hover {
	padding-left: 3.25rem;
	border-color: #fff transparent;
	color: #000;
}

.left-nav-nested-list-item-link.grandchild.w--current {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 3rem;
	border-style: solid none;
	border-width: 3px 1px;
	border-color: #fff #000;
	background-color: transparent;
	color: #000;
	font-size: 0.85rem;
	font-weight: 600;
}

.left-nav-nested-list-item-link.grandchild.w--current:hover {
	border-top-style: solid;
	border-top-color: #fff;
	border-bottom-style: solid;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-nested-list-item-link.great-grandchild {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 4rem;
	border-style: solid none;
	border-width: 3px 1px;
	border-color: #fff #000;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #000;
	font-size: 0.8rem;
	font-weight: 500;
}

.left-nav-nested-list-item-link.great-grandchild:hover {
	padding-left: 4.25rem;
	border-style: solid none;
	border-top-width: 3px;
	border-top-color: #fff;
	border-bottom-width: 3px;
	border-bottom-color: #fff;
	color: #000;
}

.left-nav-nested-list-item-link.great-grandchild.w--current {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 4rem;
	border-style: solid none;
	border-width: 3px 1px;
	border-color: #fff #000;
	color: #000;
	font-size: 0.8rem;
	font-weight: 600;
}

@media screen and (max-width: 991px) {
	.nav-link {
		display: none;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-top: 1px none #000;
		border-bottom: 1px solid rgba(51, 51, 51, 0.05);
		color: #1a2238;
	}

	.nav-link:hover {
		border-bottom: 1px solid rgba(51, 51, 51, 0.05);
		background-color: #fafbfc;
		color: #ff6a3d;
	}

	.navbar {
		position: fixed;
		width: auto;
		height: auto;
		max-width: 100%;
		padding: 0rem 2rem 0rem 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		background-color: #e8e8e8;
	}

	.nav-menu {
		height: 100vh;
		margin-right: 0rem;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		background-color: #ddd;
	}

	.nav-menu.w--open {
		background-color: #ddd;
	}

	.home-content-section {
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.body {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-content-section {
		width: 100vw;
	}

	.footer-col-rights {
		width: 75%;
		padding-left: 0vw;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.grip-link {
		width: 25%;
		padding-right: 0vw;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-container {
		left: 5%;
		padding: 1rem 2rem;
	}

	.footer-text {
		margin-bottom: 0px;
	}

	.footer-text.rights {
		width: 100%;
		margin-bottom: 0.5rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		text-align: left;
	}

	.alert-banner {
		height: auto;
	}

	.full-hero-image {
		width: 100vw;
		height: 100vh;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2))), -webkit-gradient(linear, left bottom, left top, from(#000), color-stop(65%, transparent)), url('/20170713122722/local/public/shared/assets/images/websites/bradleyriley-exterior-3.jpg');
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(360deg, #000, transparent 65%), url('/20170713122722/local/public/shared/assets/images/websites/bradleyriley-exterior-3.jpg');
		background-position: 0px 0px, 0px 0px, 0% 50%;
		background-size: auto, auto, cover;
		background-repeat: repeat, repeat, no-repeat;
	}

	.mobile-menu-search-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		float: right;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.mobile-menu-button {
		display: block;
		margin-top: 0px;
		margin-left: auto;
		padding: 0px;
		background-color: transparent;
		color: #0078b2;
		font-size: 40px;
	}

	.mobile-menu-button.w--open {
		background-color: transparent;
		color: #0078b2;
	}

	.icon-6 {
		margin-left: 1rem;
		opacity: 1;
		color: #000;
		font-size: 2rem;
	}

	.icon-6:hover {
		opacity: 1;
	}

	.search-bar {
		top: 87px;
		display: none;
		width: 100vw;
		max-width: none;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.mobile-link {
		display: block;
		padding: 1.5rem 2rem;
		float: none;
		border-bottom: 1px solid #e4e4e4;
		font-family: Montserrat, sans-serif;
		color: #000;
		font-size: 0.8rem;
		font-weight: 500;
		text-align: left;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	.mobile-link:hover {
		border-bottom: 1px solid rgba(255, 255, 255, 0.15);
		background-color: hsla(0, 0%, 100%, 0.2);
	}

	.mobile-link.w--current {
		font-family: Montserrat, sans-serif;
		color: #fff;
		font-weight: 400;
	}

	.mobile-link.w--current:hover {
		border-bottom: 1px solid rgba(255, 255, 255, 0.15);
		background-color: rgba(22, 22, 22, 0.5);
	}

	.breadcrumb-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 0.25rem;
	}

	.inside-content-beckground {
		max-width: 100%;
		padding: 0rem 3rem 3rem;
	}

	.inside-page-background-image {
		height: 30vh;
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.breadcrumb-list {
		margin-top: 1rem;
		margin-right: 0rem;
		margin-bottom: 1rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-background-heading-wrapper {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.main-content-col {
		padding-right: 0px;
		padding-left: 0px;
	}

	.main-content-container {
		display: block;
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.inside-row-nested {
		display: block;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.brand-logo {
		display: block;
		width: 275px;
		margin: 0rem 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.brand-logo.preloader {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0px;
	}

	.logo-image.mobile-view {
		display: block;
	}

	.logo-image.hide-on-mobile {
		display: none;
	}

	.footer-signoff-link {
		display: block;
		margin-bottom: 0rem;
		margin-left: 0rem;
	}

	.all-headings.hero {
		max-width: 700px;
		color: #e8e8e8;
		font-size: 3rem;
		line-height: 3rem;
		font-weight: 300;
		text-align: left;
	}

	.intro-text {
		display: inline;
		font-size: 1rem;
		line-height: 1.5rem;
		font-weight: 200;
	}

	.nav-link-wrapper {
		display: none;
	}

	.nav-link-wrapper.first-nav-link {
		display: none;
	}

	.image-overlay {
		width: 50vw;
		height: 100vh;
		padding-right: 2.5rem;
		padding-bottom: 2rem;
		padding-left: 2.5rem;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4)));
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
	}

	.page-load-animation {
		display: none;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.preloader-content {
		justify-items: center;
	}

	.logo-container {
		width: 50vw;
		height: auto;
		margin-right: auto;
		padding: 1rem 2rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		background-color: #000;
	}

	.logo-container.inside-page-logo-container {
		width: 50vw;
	}

	.inside-page-header-wrap-2 {
		display: none;
		margin-bottom: 1rem;
		padding-top: 0rem;
		padding-bottom: 1rem;
	}

	.breadcrumb-divider {
		display: none;
	}

	.breadcrumb-divider.breadcrumb-arrow {
		display: block;
	}

	.placeholder-image {
		width: 65%;
	}

	.main-content-section {
		display: block;
		width: 100%;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.main-content-section.with-right-sidebar {
		width: 100%;
	}

	.paragraph-text-link.right-sidebar {
		border-top-style: none;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.breadcrumbs-wrapper {
		width: 100%;
	}

	.right-sidebar {
		width: 100%;
		margin-top: 2rem;
		margin-left: 0rem;
		padding-top: 2rem;
		padding-left: 0rem;
		border-top: 1px solid rgba(92, 92, 92, 0.15);
		border-left-style: none;
	}

	.right-sidebar-row.last-rightsidebar-row {
		margin-bottom: 0rem;
	}

	.inside-row {
		display: block;
	}

	.primary-button-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		margin-top: 1.5rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.inner-main-content-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

@media screen and (max-width: 767px) {
	.navbar {
		padding-right: 2rem;
		padding-left: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.nav-menu {
		display: block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
	}

	.footer-col-rights {
		width: 70%;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.grip-link {
		width: 30%;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.footer-container {
		padding-right: 2rem;
		padding-bottom: 1rem;
		padding-left: 2rem;
	}

	.footer-text.rights {
		margin-bottom: 0.5rem;
		font-size: 0.65rem;
	}

	.full-hero-image {
		background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null)), -webkit-gradient(linear, left bottom, left top, from(#000), color-stop(65%, transparent)), url('/20170713122722/local/public/shared/assets/images/websites/bradleyriley-exterior-3.jpg');
		background-image: linear-gradient(180deg, null, null), linear-gradient(360deg, #000, transparent 65%), url('/20170713122722/local/public/shared/assets/images/websites/bradleyriley-exterior-3.jpg');
		background-position: 0px 0px, 0px 0px, 50% 50%;
	}

	.mobile-menu-search-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0px;
		float: none;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.mobile-menu-button {
		position: absolute;
		right: 2rem;
		margin-top: 0px;
		margin-right: 0rem;
		margin-left: 0px;
		padding: 0px;
		font-size: 20px;
	}

	.mobile-menu-button.w--open {
		background-color: transparent;
	}

	.icon-6 {
		opacity: 1;
		font-size: 2rem;
	}

	.search-bar {
		top: 87px;
		display: none;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.inside-content-beckground {
		padding: 0rem 2rem 3rem;
	}

	.inside-page-background-image {
		height: 25vh;
		min-height: 80px;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.inside-background-heading-wrapper {
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.brand-logo {
		display: block;
		width: auto;
		margin: 0rem 0px;
		padding-top: 0rem;
		padding-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		-ms-grid-row-align: stretch;
		align-self: stretch;
	}

	.footer-signoff-link {
		font-size: 0.65rem;
	}

	.all-headings.hero {
		max-width: none;
		font-size: 2.5rem;
		line-height: 2.85rem;
	}

	.intro-text {
		max-width: none;
		margin-bottom: 0.5rem;
		font-size: 1rem;
		line-height: 1.2rem;
	}

	.intro-text.bold {
		display: inline;
	}

	.image-overlay {
		width: 50vw;
		padding: 5rem 1.75rem 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.logo-container {
		width: 50vw;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.primary-button {
		margin-top: 1rem;
		padding: 0.5rem 1rem;
	}

	.inside-page-header-wrap-2 {
		padding-right: 0px;
		padding-left: 0px;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.block-quote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.h2-heading-link {
		font-size: 1.75rem;
	}

	.h5-heading-link {
		font-size: 1.125rem;
	}

	.intro-paragraph {
		font-size: 1.125rem;
	}

	.h3-heading-link {
		font-size: 1.5rem;
	}

	.secondary-button {
		margin-top: 1rem;
		padding: 0.5rem 1rem;
	}

	.tertiary-button {
		margin-top: 1rem;
		padding: 0.5rem 1rem;
	}

	.right-sidebar-row.last-rightsidebar-row {
		margin-bottom: 0rem;
	}
}

@media screen and (max-width: 479px) {
	.navbar {
		padding: 0rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.navbar.inside-navbar {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-col-rights {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-bottom: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.grip-link {
		width: 100%;
		padding-right: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-container {
		display: block;
		padding-right: 2rem;
		padding-bottom: 1rem;
		padding-left: 2rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-text {
		margin-right: 10px;
		margin-left: 0px;
		text-align: left;
		letter-spacing: 0px;
	}

	.footer-text.rights {
		margin: 0rem auto;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		line-height: 1rem;
		text-align: center;
		letter-spacing: 0px;
	}

	.alert-banner {
		display: block;
	}

	.full-hero-image {
		height: 100vh;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3))), -webkit-gradient(linear, left bottom, left top, from(#000), to(transparent)), url('/20170713122722/local/public/shared/assets/images/websites/exterior-2.jpg');
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(360deg, #000, transparent), url('/20170713122722/local/public/shared/assets/images/websites/exterior-2.jpg');
		background-position: 0px 0px, 0px 0px, 47% 50%;
		background-attachment: scroll, scroll, scroll;
	}

	.mobile-menu-search-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 40px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		align-self: auto;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.mobile-menu-button {
		right: 1rem;
		margin-top: 0px;
		margin-right: 0rem;
		margin-left: 0px;
	}

	.icon-6 {
		margin-left: 0rem;
		opacity: 1;
		color: #000;
	}

	.news-inner-content {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.search-bar {
		top: 77px;
		display: none;
	}

	.inside-content-beckground {
		padding-right: 1.75rem;
		padding-left: 1.75rem;
	}

	.inside-page-background-image {
		height: 20vh;
		padding-right: 1.75rem;
		padding-left: 1.75rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.inside-background-heading-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: auto;
		max-width: none;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.brand-logo {
		width: 200px;
		margin: 0rem auto 0.25rem 0px;
		padding-top: 0rem;
		padding-left: 0px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.brand-logo.preloader {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0px;
	}

	.logo-image {
		max-width: 100%;
		padding-right: 0px;
	}

	.footer-signoff-link {
		margin-right: 0.25rem;
		margin-bottom: 0.25rem;
		margin-left: 0rem;
	}

	.all-headings.hero {
		margin-bottom: 1rem;
		color: #e8e8e8;
		font-size: 2.75rem;
		line-height: 2.85rem;
		text-align: center;
	}

	.intro-text {
		max-width: none;
		margin-bottom: 0.5rem;
		font-size: 1rem;
		line-height: 1rem;
		text-align: center;
	}

	.image-overlay {
		width: 100vw;
		height: 100vh;
		padding: 2rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
	}

	.preloader-content {
		justify-items: center;
	}

	.intro {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.logo-container {
		width: 50vw;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.primary-button {
		margin-top: 2rem;
		color: #e4e4e4;
	}

	.h1-heading {
		font-size: 40px;
	}

	.button-styling-wrapper {
		padding-left: 1rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.image.recent-blog-post {
		height: 250px;
	}

	.placeholder-image {
		width: 100%;
		height: auto;
	}

	.block-quote {
		padding-right: 15px;
		padding-left: 15px;
	}

	.paragraph {
		font-size: 0.95rem;
		line-height: 1.35rem;
	}

	.paragraph-text-link {
		font-size: 0.9rem;
	}

	.intro-paragraph {
		line-height: 1.5rem;
	}

	.secondary-button {
		margin-top: 2rem;
		color: #e4e4e4;
	}

	.tertiary-button {
		margin-top: 2rem;
		color: #e4e4e4;
	}

	.breadcrumbs-wrapper {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.paragraph-text-link-2 {
		font-size: 0.9rem;
	}

	.right-sidebar-row.last-rightsidebar-row {
		margin-bottom: 0rem;
	}

	.primary-button-2 {
		margin-top: 1rem;
		margin-right: 0rem;
		font-size: 0.8rem;
	}

	.screen-message-text {
		font-size: 1rem;
		line-height: 1.35rem;
	}
}

#w-node-_7d42c2cf-a58a-3fe3-64a1-1802da2d73da-6a2d7413 {
	-ms-grid-column-align: center;
	justify-self: center;
}

@media screen and (max-width: 991px) {
	#w-node-_7d42c2cf-a58a-3fe3-64a1-1802da2d73da-6a2d7413 {
		-ms-grid-column-align: center;
		justify-self: center;
	}
}


/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.navbar.inside-navbar {
	background-color: rgb(221, 221, 221);
}

@media (max-width: 767px) {
	.logo-container.inside-page-logo-container {
		min-width: 265px;
	}
}

@media (max-width: 479px) {
	.logo-container.inside-page-logo-container {
		min-width: 210px;
	}
}

/****************** Accesible menu **********************/
/*****************************************************/
.homepage-styles .accesible-navigation-menu {
	width: 100%;
}

.homepage-styles .accesible-navigation-menu ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	width: 100%;
}

.homepage-styles .accesible-navigation-menu ul li.nav-link-wrapper:first-child {
	border-top: 1px none #e8e8e8;
}


.homepage-styles .accesible-navigation-menu ul li.nav-link-wrapper:last-child {
	border-bottom: 0px;
}

.inside-styles .nav-menu {
	width: auto;
	height: 100%;
	flex-direction: row;
	justify-content: flex-end;
	align-items: stretch;
	flex: 0 auto;
}

.inside-styles .accesible-navigation-menu ul {
	margin: 0px;
	padding: 0px;
}

.inside-styles .accesible-navigation-menu ul li.nav-link-wrapper:first-child {
	border-right: 1px solid #e8e8e8;
	border-left: 1px solid #e8e8e8;
	border-bottom-style: none;
}

.inside-styles .accesible-navigation-menu ul li.nav-link-wrapper {
	width: auto;
	border-right: 1px solid #e8e8e8;
	border-left: 1px none #e8e8e8;
	border-bottom-style: none;
}

.inside-styles .accesible-navigation-menu ul li.nav-link-wrapper .nav-link {
	width: auto;
	height: 100%;
	padding-right: 1.65rem;
	padding-left: 1.65rem;
	border: 1px none #000;
}

.inside-styles .accesible-navigation-menu ul li.nav-link-wrapper:last-child {
	width: auto;
	margin-right: 2rem;
	border-right: 1px solid #e8e8e8;
}

.all-headings.hero a {
	margin-bottom: 0px;
}

@media (min-width: 992px) {
	.accesible-navigation-menu {
		display: block;
	}
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none;
	}
}
/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mobile-menu-button {
		display: none;
	}

	.mm-page.mm-slideout {
		width: 100% !important;
		display: inherit !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #ddd;
}

.mobile-navigation-menu li a.nav-a,
.mm-navbar__title {
	text-align: left;
	padding: 1.5rem 2rem;
	font-family: Montserrat, sans-serif;
	color: #000 !important;
	font-size: 0.8rem;
	font-weight: 500;
	text-align: left;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.mm-listitem:after {
	left: 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mm-navbar {
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-navigation-menu li.mm-listitem.active,
.mobile-navigation-menu li.mm-listitem:hover {
	background-color: hsla(0, 0%, 100%, 0.2);
}

.mm-listitem_selected > .mm-listitem__text {
	background-color: transparent;
}

.mm-navbar > * {
	justify-content: flex-start;
}

.mm-btn_next:after,
.mm-btn_prev:before {
	border-color: #000;
}

.mm-listitem__btn {
	border-color: rgba(255, 255, 255, 0.15);
}

/********************* Homepage *********************/
/*****************************************************/
.all-headings.hero a,
.all-headings.hero a:hover {
	color: inherit;
	line-height: inherit;
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
	border: 1px solid transparent;
	text-decoration: none;
}

.intro-text p {
	margin-bottom: 0.25rem;
	margin-top: 0px;
}

.intro-text p,
.intro-text td,
.intro-text li,
.intro-text span {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	font-family: inherit;
}

.intro-text p:last-child {
	margin-bottom: 0px;
}

.intro-text a,
.intro-text h1,
.intro-text h2,
.intro-text h3,
.intro-text h4,
.intro-text h5,
.intro-text h6 {
	color: inherit;
}

.intro-text strong,
.intro-text b {
	font-weight: 500;
}

/****************** Inside Page ******************/
/*************************************************/
@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumb-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list li:last-child a {
	border-bottom-style: none;
	color: #000;
	font-weight: 600;
}

ul.left-nav-nested-list {
	background-color: rgba(51, 51, 51, 0.05);
}

ul.left-nav-nested-list li:last-child {
	border-bottom: 0px;
}

.left-nav-nested-list {
	border-top: 1px solid rgba(51, 51, 51, 0.05);
}

.left-nav-nested-list.great-grandchild-link li:first-child {
	border-top: 2px solid rgba(51, 51, 51, 0.05);
}

.left-nav-list-link.active,
.left-nav-nested-list-item-link.active,
.left-nav-nested-list-link.great-grandchild.active {
	color: #000;
	font-weight: 600;
}

.no-columns .main-content-col {
	margin-left: auto;
	margin-right: auto;
	max-width: 1400px;
}

.no-columns .main-content-section.with-right-sidebar {
	width: 100%;
}

.left-column .main-content-section.with-right-sidebar {
	width: 100%;
}

.right-column .inside-background-heading-wrapper {
	margin-left: 0px;
}

.no-columns .inside-background-heading-wrapper {
	max-width: 1400px;
	margin-right: 0px;
	margin-left: 0px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

@media (max-width: 991px) {
	.left-column .inside-background-heading-wrapper,
	.both-columns .inside-background-heading-wrapper {
		margin-left: 0;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-sidebar .box {
	margin-bottom: 2rem;
	font-size: 0.85rem;
}

.right-sidebar .box p,
.right-sidebar .box li,
.right-sidebar .box td,
.right-sidebar .box a,
.right-sidebar .box span {
	font-size: 0.85rem;
}

.right-sidebar .box p {
	margin-bottom: 1rem;
	margin-left: 0rem;
}

.right-sidebar .box h4,
.right-sidebar .box h3 {
	font-size: 18px;
	line-height: 24px;
	margin-top: 10px;
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-weight: 500;
	text-transform: capitalize;
}

.right-sidebar .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar .box.tools td:first-child img {
	margin-right: 7px;
}

.right-sidebar .box a {
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 0.85rem;
	line-height: 1rem;
	text-align: left;
	text-decoration: none;
}

.right-sidebar .box a:hover {
	color: #6e6e6e;
}

.right-sidebar td.contentbox_item_image img {
	max-width: none;
}

/********************* Footer **********************/
/*****************************************************/
#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.left-sidebar a,
.main-content-section a,
.cboxes a {
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.12);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #6e6e6e;
	font-weight: 400;
	text-decoration: none;
}

.left-sidebar a:hover,
.main-content-section a:hover,
.cboxes a:hover {
	border-bottom: 1.5px solid transparent;
}

h1 {
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 45px;
	font-weight: 300;
}

@media screen and (max-width: 479px) {
	h1 {
		font-size: 40px;
	}
}

h2 {
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-weight: 400;
}

h3 {
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-weight: 400;
}

h4 {
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
}

h5 {
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-weight: 500;
	text-transform: capitalize;
}

h6 {
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-weight: 500;
	text-transform: capitalize;
}

.left-sidebar h1 a,
.main-content-section h1 a,
.right-sidebar h1 a,
.cboxes h1 a,
h1 a {
	line-height: 52px;
	color: #000;
}

.left-sidebar h2 a,
.main-content-section h2 a,
.right-sidebar h2 a,
.cboxes h2 a,
h2 a {
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 400;
	text-decoration: none;
}

.left-sidebar h3 a,
.main-content-col h3 a,
.right-nav-col h3 a,
.cboxes h3 a,
h3 a {
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
	text-decoration: none;
}

.left-sidebar h4 a,
.main-content-col h4 a,
.right-nav-col h4 a,
.cboxes h4 a,
h4 a {
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.12);
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-decoration: none;
}

.left-sidebar h5 a,
.main-content-col h5 a,
.right-nav-col h5 a,
.cboxes h5 a,
h5 a {
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.left-sidebar h6 a,
.main-content-col h6 a,
.right-nav-col h6 a,
.cboxes h6 a,
h6 a {
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #000;
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a,
h6:hover a {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #6e6e6e;
}

p {
	margin-bottom: 1.5rem;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

@media screen and (max-width: 767px) {
	.left-sidebar h2 a,
	.main-content-section h2 a,
	.right-sidebar h2 a,
	.cboxes h2 a,
	h2 a {
		font-size: 1.75rem;
	}

	.left-sidebar h3 a,
	.main-content-col h3 a,
	.right-nav-col h3 a,
	.cboxes h3 a,
	h3 a {
		font-size: 1.5rem;
	}

	.left-sidebar h5 a,
	.main-content-col h5 a,
	.right-nav-col h5 a,
	.cboxes h5 a,
	h5 a {
		font-size: 1.125rem;
	}
}

@media screen and (max-width: 479px) {
	p {
		font-size: 0.95rem;
		line-height: 1.35rem;
	}
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	padding: 0.75rem 1.25rem;
	border-style: solid;
	border-width: 1px;
	border-color: #fff;
	background-color: transparent;
	opacity: 1;
	transition: all 350ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.65rem;
	font-weight: 500;
	letter-spacing: 2px;
	text-transform: uppercase;
	cursor: pointer;
	text-decoration: none;
	background-color: silver;
}

.primary:hover,
.inside-row a.primary:hover .inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.homeboxes a.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	color: #000 !important;
	background-color: #fff !important;
	border-color: #000 !important;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary {
	display: inline-block;
	padding: 0.75rem 1.25rem;
	border-style: solid;
	border-width: 1px;
	border-color: #6e6e6e;
	background-color: #6e6e6e;
	opacity: 1;
	transition: all 350ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.65rem;
	font-weight: 500;
	letter-spacing: 2px;
	text-transform: uppercase;
	cursor: pointer;
	text-decoration: none;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover {
	color: #fff !important;
	background-color: #000 !important;
	border-color: #000 !important;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	padding: 0.75rem 1.25rem;
	border-style: solid;
	border-width: 1px;
	border-color: #000;
	background-color: #000;
	opacity: 1;
	transition: all 350ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.65rem;
	font-weight: 500;
	letter-spacing: 2px;
	text-transform: uppercase;
	cursor: pointer;
	text-decoration: none;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: #fff !important;
	background-color: #6e6e6e !important;
	border-color: #6e6e6e !important;
}

/* messages */
#message.success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: rgba(154, 199, 151, 0.5);
	color: #213008;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

#message.error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: rgba(198, 40, 40, 0.15);
	color: #aa1a1a;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}


#message.success p {
	color: #213008;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

#message.error p {
	color: #aa1a1a;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #000000;
	opacity: 100%;
	color: #ffffff;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	border-color: #525252;
	background-color: #6e6e6e;
	opacity: 100%;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #dddddd;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	font-size: 0.875rem;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	margin-top: 2rem;
	margin-bottom: 2rem;
	padding: 1rem 1.5rem;
	border-left-color: #000;
	background-color: transparent;
	font-family: Montserrat, sans-serif;
	color: #6e6e6e;
	font-size: 1.3rem;
	line-height: 1.75rem;
	font-weight: 500;
}

blockquote p {
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

@media (max-width: 767px) {
	blockquote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}
}

@media (max-width: 479px) {
	blockquote {
		padding-right: 15px;
		padding-left: 15px;
	}
}


/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333333;
	font-style: italic;
}

#pagination span {
	color: #333 !important;
}

/************************ Modules ********************/
/*****************************************************/
/* locations */
#locations_module.list .location-item {
	width: 100%;
}

#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(91, 91, 91, 0.15);
}

#locations_module.list .type_item .title,
#locations_module.list .type_item .title a {
	font-size: 1.5rem;
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(91, 91, 91, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}


/* posts */
.posts-node .posts-comments .required_information {
	color: #ee0000;
	font-style: italic;
}

.posts-collection > .node {
	border-top: 1px solid rgba(91, 91, 91, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: rgb(51, 51, 51);
	margin-top: 20px;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
	background-color: #fff;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid #333;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.posts-blocks .posts-blocks-date-filter form.posts-blocks-date-filter-form select:last-of-type {
	margin-bottom: 10px;
}

.right-sidebar .box.posts-blocks.posts-blocks-rss-feeds a,
.right-sidebar .box.posts-blocks.posts-blocks-subscribes a {
	border-bottom: 0px;
	display: flex;
	align-items: center;
}


.right-sidebar .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-sidebar .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-sidebar .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-sidebar .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.right-sidebar .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 0;
}

.right-sidebar .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

.right-sidebar .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 7px 10px 7px 10px !important;
	background-color: #333 !important;
	border: 1px solid #333;
}

/* submissions form */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .sigWrapper.current {
	border-color: #666;
}

/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 5px;
	font-size: 1rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: rgba(92, 92, 92, 0.15);
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	font-size: 0.9rem;
	margin-top: 10px;
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: bold;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.75rem 1.25rem;
	margin-top: 10px;
}

.right-sidebar .box #frmDepartments {
	margin-top: 0.75rem;
}

.right-sidebar .box #frmDepartments #Department {
	width: calc(100% - 55px);
}

.right-sidebar .box #frmDepartments button[type="submit"].primary {
	padding: 7px 15px !important;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}



/****************** Content Boxes  *******************/
/*****************************************************/
/* news */
#news_module.homepage_contentbox p,
#news_module.homepage_contentbox a,
#news_module.homepage_contentbox td,
#news_module.homepage_contentbox h2,
#news_module.homepage_contentbox h3,
#news_module.homepage_contentbox h4,
#news_module.homepage_contentbox h5,
#news_module.homepage_contentbox h6 {
	color: #fff;
}

#news_module.homepage_contentbox .cms_title h3 a {
	font-size: 1.1rem;
	margin-bottom: 0px;
	margin-top: 0px;
}

#news_module.homepage_contentbox .cms_title h3 {
	margin-bottom: 0px;
}

#news_module.homepage_contentbox .cms_date h3 {
	font-size: 0.9rem;
	margin-bottom: 0px;
	margin-top: 0px;
}

/* posts */
.home-content-section .posts-collection p,
.home-content-section .posts-collection a,
.home-content-section .posts-collection td,
.home-content-section .posts-collection h2,
.home-content-section .posts-collection h3,
.home-content-section .posts-collection h4,
.home-content-section .posts-collection h5,
.home-content-section .posts-collection h6 {
	color: #fff;
}

.posts-collection.homepage-contentbox.article-items .article-image {
	max-width: 230px;
}

.home-content-section .posts-collection.homepage-contentbox.article-items .article {
	width: 100% !important;
}

.posts-collection.homepage-contentbox.article-items .article-date {
	background: none;
	padding-left: 0px;
}

/* forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}



/********************** Law Firm  *******************/
/*****************************************************/
/* practice areas */
#module-practiceareas .d_practice {
	margin-bottom: 20px;
	padding: 20px;
	background-color: #fafafa;
}

#module-practiceareas .d_list-heading {
	margin-top: 10px;
	font-weight: 400;
}

#module-practiceareas .d_list-heading a {
	color: #000;
}

#module-practiceareas .d_subheading {
	margin-top: 10px;
	font-size: 18px;
	font-weight: 400;
}

#module-practiceareas .d_list_subpractices .d_list_subpractice_box {
	margin-bottom: 10px;
}

#module-practiceareas .d_list_subpractices .d_list_subpractice_box a.d_list_subpractice {
	color: #000;
}

#module-practiceareas .d_practice-desc {
	margin-right: 20px;
}

#module-practiceareas .d_practice-desc a {
	color: #000;
}

#module-practiceareas .d_practice-thumbnail {
	margin-bottom: 15px;
	margin-top: 15px;
}

#module-practiceareas .d_list_subpractice {
	display: inline-block;
	margin-bottom: 10px;
	float: left;
	clear: left;
}

#module-practiceareas .d_practice-image {
	height: 350px;
	margin-bottom: 20px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

#module-practiceareas .d_practice-area-item {
	margin-right: 20px;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #000;
}

#module-practiceareas .d_heading {
	margin-top: 0px;
}

#module-practiceareas .d_practice-side-section {
	margin-bottom: 20px;
	padding: 5px 20px 20px;
	background-color: #fafafa;
	font-size: 0.9rem;
	line-height: 1.5rem;
}

#module-practiceareas .d_practice-side-section li {
	margin-bottom: 10px;
}

#module-practiceareas .d_practice-side-section li a {
	color: #000;
}

#module-practiceareas .d_practice-main-section:not(.has-children) {
	width: 100%;
}

@media (max-width: 991px) {
	#module-practiceareas .d_practice-image {
		height: 250px;
	}
}

@media (max-width: 767px) {
	#module-practiceareas .d_practice-image {
		height: 200px;
	}

	#module-practiceareas .d_practice-area-item {
		margin-right: 0px;
	}
}

@media (max-width: 479px) {
	#module-practiceareas .d_practice-image {
		height: 150px;
	}
}

/* attorney listing  - aplhavetical/practice listing */
#module-attorneys.alpha a,
#module-attorneys.practice a {
	border-bottom: 0px;
	color: #000;
	font-weight: normal;
}

.d_list-subheading {
	margin-top: 20px;
	margin-bottom: 10px;
}

.d_list-item {
	margin-bottom: 10px;
	font-size: 1rem;
}

.d_list-name {
	display: inline-block;
	width: 42%;
}

.d_list-phone {
	display: inline-block;
	width: 20%;
	margin-bottom: 0px;
	font-size: 0.95rem;
	line-height: 1.1rem;
	padding-right: 2%;
}

.d_list_location {
	display: inline-block;
	width: 20%;
	margin-bottom: 0px;
	padding-right: 2%;
	font-size: 0.95rem;
	line-height: 1.1rem;
}

.d_list-email {
	display: inline-block;
	font-size: 0.95rem;
	line-height: 1.1rem;
	width: auto;
	padding-left: 25px;
	background-image: url('/local/website/modules/attorneys/assets/images/email-icon.svg');
	background-position: 0px 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
}

#module-attorneys-top-navigation.nav-list {
	border: 0;
	background-color: #fafafa;
	padding: 15px;
}

#module-attorneys-top-navigation.nav-list a {
	border-bottom: 1px solid transparent;
	color: #000;
}

#module-attorneys.practice .d_list-subheading {
	font-size: 28px;
}

#module-attorneys.practice h3 {
	font-size: 23px;
}

@media (max-width: 767px) {
	.d_list-item {
		padding: 15px;
		padding-left: 0px;
	}

	.d_list-name {
		display: block;
		width: 100%;
		font-size: 1.1rem;
		margin-bottom: 5px;
	}

	.d_list_location {
		display: block;
		font-size: 0.9rem;
		width: 100%;
	}

	.d_list-phone {
		display: block;
		width: 100%;
		font-size: 0.9rem;
	}

	.d_list-email {
		display: block;
		width: 100%;
		margin-top: 10px;
		padding-left: 30px;
		background-size: 18px;
		font-size: 0.9rem;
	}
}

/* attorney listing  - contact form*/
@media (max-width: 991px) {
	#attorneys_module.cms_form .cms_field input[type="text"],
	#attorneys_module.cms_form .cms_field textarea {
		max-width: 100%;
	}
}


/* attorneys - profile*/
#module-attorneys.profile li {
	margin-bottom: 7px;
}

.d_profile-header {
	height: 350px;
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: nowrap;
	align-items: center;
}

.d_contact {
	height: 100%;
	padding: 30px 10px 30px 30px;
	background-color: #fffc;
	background-image: -webkit-linear-gradient(270deg, #0000, #ccc6);
	background-image: linear-gradient(180deg, #0000, #ccc6);
	font-size: 1rem;
	color: #000;
	/* width: 100%; */
	flex: 1;
}

.d_contact a {
	color: #000;
}

.d_contact.full-width {
	width: 100%;
}

.d_contact.full-width .d_social {
	text-align: left;
	padding-left: 30px;
	margin-left: 0px;
}

.d_page-title {
	font-weight: 400;
	margin-top: 0px;
	margin-bottom: 20px;
	line-height: 40px;
	font-size: 36px;
}

.d_contact-item {
	margin-bottom: 15px;
}

.d_contact-info-label {
	display: inline-block;
	width: 80px;
	margin-right: 20px;
	margin-bottom: 0px;
	float: left;
	text-align: left;
}

.d_social {
	margin-top: 20px;
	margin-left: 0;
}

.d_social-a {
	margin-right: 20px;
	padding-left: 25px;
	border-bottom: 0px !important;
}

.d_social-a.email {
	display: none;
	background-image: url('/local/website/modules/attorneys/assets/images/email-icon.svg');
	background-position: 0px 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
}

.d_social-a.vcard {
	background-image: url('/local/website/modules/attorneys/assets/images/vcard-icon.svg');
	background-position: 0px 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	margin-bottom: 7px;
}

.d_social-a.linkedin {
	background-image: url('/local/website/modules/attorneys/assets/images/linkedin-icon.svg');
	background-position: 0px 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	margin-bottom: 7px;
}

.d_social-a.print {
	background-image: url('/local/website/modules/attorneys/assets/images/print-icon.svg');
	background-position: 0px 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	margin-bottom: 7px;
}

.d_profile-photo {
	display: block;
	width: 350px;
	height: 100%;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	max-width: 50%;
}

.d_profile-block {
	margin-bottom: 20px;
	padding: 30px;
	background-color: #fafafa;
}

.d_profile-block p,
.d_profile-block li,
.d_profile-block a,
.d_profile-block td,
.d_profile-block span {
	color: #000;
}

.d_profile-block .full-width {
	width: 100%;
}

.d_profile-block.awards {
	text-align: center;
}

.d_profile-block.awards img {
	margin-bottom: 20px;
	margin-right: 20px;
}

.d_heading {
	margin-top: 0px;
}


@media (min-width: 768px) {
	.d_contact-info-label {
		text-align: left;
	}
}

@media (max-width: 767px) {
	.d_profile-header {
		height: auto;
		flex-direction: column;
	}

	.d_contact {
		position: relative;
		width: 100%;
		height: auto;
		background-color: #fff;
	}

	.d_social {
		text-align: left;
	}

	.d_social-a {
		padding-left: 35px;
	}

	.d_social-a.vcard {
		background-size: 20px;
	}

	.d_social-a.linkedin {
		background-size: 20px;
	}

	.d_social-a.print {
		background-size: 20px;
	}

	.d_profile-photo {
		display: block;
		height: 300px;
		width: 100%;
		max-width: 100%;
		background-size: cover;
	}

	.d_profile-block :not(.full-width) .d_heading.education {
		margin-top: 20px;
	}
}

@media (max-width: 479px) {
	.d_contact-item.email {
		display: none;
	}

	.d_contact-info-label {
		display: block;
		width: 80px;
		margin-right: 15px;
		text-align: left;
	}

	.d_social-a {
		display: block;
		margin-bottom: 15px !important;
		margin-right: 0px;
	}

	.d_social-a.email {
		display: block;
		background-size: 20px;
	}

	.d_contact.full-width .d_social {
		padding-left: 0px;
	}
}
